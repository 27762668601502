/* Global Styles */
body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: #EAEAEA;
  }
  
  /* Home Container */
  .home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2% 5%;
  }
  
  /* Content Container */
  .content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 3%;
  }
  
  /* Color Panel */
  .color-panel {
    background-color: #fff;
    width: 100%;
    max-width: 800px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  
  /* Color Info Section */
  .color-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    border-bottom: 2px solid #ddd;
    padding-bottom: 10px;
  }
  
  .color-preview {
    width: 70px;
    height: 30px;
    box-shadow: 0px 0px 10px 0px black;
    border-radius: 4px;
  }
  
  /* Picker and Display Section */
  .picker-display {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    width: 100%;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .color-panel {
      width: 90%;
      padding: 15px;
    }
  
    .color-info {
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }
  }
  
  @media (max-width: 480px) {
    .color-info {
      font-size: 14px;
    }
  
    .color-preview {
      width: 50px;
      height: 20px;
    }
  }
  

    