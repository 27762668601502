/* Footer Styles */
.footer {
    background-color: #333;
    color: #fff;
    padding: 50px 0;
    text-align: center;
    font-family: Arial, sans-serif;
    margin-top: 50px;
  }
  
  .footer-content {
    width: 80%;
    margin: 0 auto;
  }
  
  .footer h3 {
    font-size: 32px;
    margin-bottom: 20px;
  }
  
  .footer p {
    font-size: 16px;
    margin-bottom: 20px;
    line-height: 1.5;
  }
  
  .cta-button {
    background-color: #ff6347;
    color: white;
    border: none;
    padding: 12px 25px;
    font-size: 18px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
    margin-bottom: 30px;
  }
  
  .cta-button:hover {
    background-color: #ff4500;
  }
  
  .footer-links ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-links li {
    display: inline-block;
    margin: 10px 15px;
  }
  
  .footer-links a {
    color: #fff;
    text-decoration: none;
    font-size: 16px;
  }
  
  .footer-links a:hover {
    text-decoration: underline;
  }
  