/* HEX.css */

.hex-container {
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin: 50px auto;
    text-align: center;
  }
  
  h2 {
    color: #333;
    margin-bottom: 20px;
  }
  
  .input-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
  }
  
  label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  input {
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ddd;
    width: 100%;
    max-width: 200px;
    margin-bottom: 15px;
    text-align: center;
  }
  
  .rgb-output {
    margin-bottom: 20px;
    font-size: 16px;
    color: #555;
  }
  
  .convert-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .convert-button:hover {
    background-color: #0056b3;
  }
  
  .color-display {
    margin-top: 20px;
    width: 100px;
    height: 100px;
    border-radius: 10px;
    border: 1px solid #ddd;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-left: auto;
    margin-right: auto;
  }
  