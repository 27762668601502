/* Basic navbar styles */
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333; /* Background color for the navbar */
  padding: 10px 20px;   
  position: relative;
}

/* Logo styling */
.logo {
  display: flex;
  align-items: center;
}

.logo-img {
  height: 60px; /* Adjust the logo size */
  width: auto;
}
.logo_de{
  display: flex;
  align-items: center;
  color: white;
}

/* Hamburger Menu Button (Visible only on mobile) */
.hamburger {
  display: none;
  font-size: 30px;
  color: white; /* Color of the hamburger button */
  background-color: #444; /* Background color for the hamburger button */
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

/* Navigation Links */
.nav-links {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav-links li {
  margin-right: 20px; /* Space between links */
}

.nav-item {
  color: white; /* Color of the links */
  font-size: 18px;
  text-decoration: none;
  padding: 10px;
}

.nav-item:hover {
  background-color: #555; /* Hover effect */
  border-radius: 5px;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .nav-links {
    display: none; /* Hide the menu by default on mobile */
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #333; /* Background color for the menu */
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  .nav-links.show {
    display: flex; /* Show the menu when toggled */
  }

  /* Show hamburger button on small screens */
  .hamburger {
    display: block;
  }

  /* Styling for individual menu items */
  .nav-links li {
    margin: 20px 0; /* Space out items in the mobile menu */
  }

  .nav-item {
    font-size: 22px; /* Adjust font size for mobile */
  }
}
