/* ColorChart.css */

.colorchart-container {
    padding: 20px;
    text-align: center;
    background-color: #f5f5f5;
    min-height: 100vh;
  }
  
  h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .color-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 20px;
    justify-items: center;
    align-items: center;
    margin-bottom: 40px;
  }
  
  .color-card {
    width: 140px;
    height: 130px;  /* Adjusted to accommodate both Hex and RGB */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #fff;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .color-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .color-name {
    font-weight: bold;
    margin: 5px 0;
  }
  
  .color-hex, .color-rgb {
    font-size: 12px;
    margin: 2px 0;
    text-align: center;
  }
  